import React, { useState } from "react"
import { states } from "../../utils/utils"

import { formQuestions } from "./form-client.module.scss"

const FormClient = () => {
  const [formData, updateForm] = useState({
    emailAddress: "",
    companyName: "",
    firstName: "",
    lastName: "",
    city: "",
    state: "",
    phone: "",
    orgDescription: "",
    website: "",
    yearsInOperation: "",
    budgetRange: "",
    annualRevenue: "",
    primaryGoal: "",
    priority: "",
    startDate: "",
    desiredCompletionDate: "",
    projectVision: "",
    botField: "",
  })

  const encode = (formData) => {
    return Object.keys(formData)
      .map(
        (key) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(formData[key])
      )
      .join("&")
  }

  const [isSubmitted, updateSubmit] = useState(false)

  const handleSubmit = (evt) => {
    evt.preventDefault()
    console.log({ formData })

    const form = evt.target

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...formData,
      }),
    })
      .then(() => {
        // navigate(formData.getAttribute('action'))
        updateSubmit(true)
      })
      .catch((error) => alert(error))
  }

  const handleChange = ({ target }) => {
    const { name, value } = target

    updateForm({
      ...formData,
      [name]: value,
    })
  }

  const budgetRanges = [
    "$10,000 - $19,000",
    "$20,000 - $29,000",
    "$30,000 - $39,000",
    "$40,000 - $49,000",
    "$50,000+",
  ]

  const goals = [
    "Build Awareness",
    "More Customers",
    "Reduce Call Volume",
    "Website Redesign",
  ]

  const years = [
    "Less than 1",
    "1 - 2 years",
    "3 - 5 years",
    "6 - 10 years",
    "10+ years",
  ]

  return (
    <>
      {!isSubmitted ? (
        <form
          className={`${formQuestions} elr-form`}
          onSubmit={handleSubmit}
          data-netlify="true"
          data-netlify-honeypot="botField"
          method="post"
          name="questions"
          noValidate
        >
          <input type="hidden" name="form-name" value="questions" />
          <label className="elr-screen-reader" hidden>
            Don’t fill this out:{" "}
            <input name="botField" onChange={handleChange} />
          </label>
          <fieldset>
            <h3>Tell Us About Your Business</h3>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="companyName">Company Name:</label>
                <input
                  type="text"
                  onChange={handleChange}
                  defaultValue={formData.companyName}
                  id="companyName"
                  name="companyName"
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="firstName">First Name:</label>
                <input
                  type="text"
                  onChange={handleChange}
                  defaultValue={formData.firstName}
                  id="firstName"
                  name="firstName"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Last Name:</label>
                <input
                  type="text"
                  onChange={handleChange}
                  defaultValue={formData.lastName}
                  id="lastName"
                  name="lastName"
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="city">City:</label>
                <input
                  type="text"
                  onChange={handleChange}
                  defaultValue={formData.city}
                  id="city"
                  name="city"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="state">State:</label>
                <div className="elr-select-wrapper">
                  <select
                    name="state"
                    id="state"
                    required
                    onBlur={handleChange}
                    defaultValue={formData.state}
                  >
                    <option value="">Choose a State</option>
                    {states.map((o) => (
                      <option key={o} value={o}>
                        {o}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="emailAddress">Email Address:</label>
                <input
                  type="email"
                  onChange={handleChange}
                  defaultValue={formData.emailAddress}
                  id="emailAddress"
                  name="emailAddress"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone:</label>
                <input
                  type="tel"
                  onChange={handleChange}
                  defaultValue={formData.phone}
                  id="phone"
                  name="phone"
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="website">Website:</label>
                <input
                  type="url"
                  onChange={handleChange}
                  defaultValue={formData.website}
                  id="website"
                  name="website"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="years-operating">Years in Operation:</label>
                <div className="elr-select-wrapper">
                  <select
                    name="yearsInOperation"
                    id="years-operating"
                    required
                    onBlur={handleChange}
                    defaultValue={formData.yearsInOperation}
                  >
                    <option value="">Choose a Range</option>
                    {years.map((o) => (
                      <option key={o} value={o}>
                        {o}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="revenue">Annual Revenue:</label>
                <input
                  type="text"
                  onChange={handleChange}
                  defaultValue={formData.revenue}
                  id="revenue"
                  name="revenue"
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="budget-range">Budget Range:</label>
                <div className="elr-select-wrapper">
                  <select
                    name="budgetRange"
                    id="budget-range"
                    required
                    onBlur={handleChange}
                    defaultValue={formData.budgetRange}
                  >
                    <option value="">Choose a Range</option>
                    {budgetRanges.map((o) => (
                      <option key={o} value={o}>
                        {o}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="primary-goal">Primary Goal:</label>
                <div className="elr-select-wrapper">
                  <select
                    name="primaryGoal"
                    id="primary-goal"
                    required
                    onBlur={handleChange}
                    defaultValue={formData.primaryGoal}
                  >
                    <option value="">Choose a Goal</option>
                    {goals.map((o) => (
                      <option key={o} value={o}>
                        {o}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="startDate">Start Date:</label>
                <input
                  type="date"
                  onChange={handleChange}
                  defaultValue={formData.startDate}
                  id="startDate"
                  name="startDate"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="completeDate">Complete Date:</label>
                <input
                  type="date"
                  onChange={handleChange}
                  defaultValue={formData.completeDate}
                  id="completeDate"
                  name="completeDate"
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="project-vision">
                  Describe your long term goals for the project
                </label>
                <textarea
                  onChange={handleChange}
                  required
                  name="projectVision"
                  id="project-vision"
                  cols="20"
                  rows="5"
                ></textarea>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="org-description">
                  Please describe your organization in a few sentences
                </label>
                <textarea
                  onChange={handleChange}
                  required
                  name="orgDescription"
                  id="org-description"
                  cols="20"
                  rows="5"
                ></textarea>
              </div>
            </div>
          </fieldset>
          <button type="submit">Send Message</button>
        </form>
      ) : (
        <p className="form-message">Thank you for contacting us!</p>
      )}
    </>
  )
}

export default FormClient
