import React from "react"
import SeoHeaders from "../components/seo-headers/seo-headers"
import LayoutMain from "../components/layout-main/layout-main"
import HeaderMain from "../components/header-main/header-main"
import FormClient from "../components/form-client/form-client"

const ClientQuestions = ({
  isLoggedIn = process.env.GATSBY_ACTIVE_ENV === "development" ||
    process.env.GATSBY_ACTIVE_ENV === "staging",
}) => {
  const startDate = "April 1, 2021"
  return (
    <LayoutMain className="page page-questionnaire">
      <SeoHeaders title="Questionnaire" keywords={[`Questionnaire`]} />
      <HeaderMain />
      <section>
        <div className="content-section">
          <div className="content-section-inner">
            <div className="page-content">
              <h2 className="main-heading">Initial Client Questionnaire</h2>
              {isLoggedIn ? (
                <>
                  <p>
                    We are currently accepting projects with a start date of{" "}
                    {{ startDate }}
                  </p>
                  <FormClient />
                </>
              ) : (
                <p>Sign up to view application</p>
              )}
            </div>
          </div>
        </div>
      </section>
    </LayoutMain>
  )
}

export default ClientQuestions
